import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";

const CoolblueApp = () => (
  <StaticQuery
    query={graphql`
      query {
        cbApp1: file(relativePath: { eq: "cb-app-1.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cbApp2: file(relativePath: { eq: "cb-app-2.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cbApp3: file(relativePath: { eq: "cb-app-3.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cbApp4: file(relativePath: { eq: "cb-app-4.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cbApp5: file(relativePath: { eq: "cb-app-5.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet>
          <title>Coolblue Apps | Charlotte de Hilster</title>
        </Helmet>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 45%;
            grid-column-gap: 24px;
            width: 100%;
            @media only screen and (max-width: 600px) {
              grid-template-columns: 1fr;
            }
          `}
        >
          <div
            css={css`
              @media only screen and (max-width: 600px) {
                grid-row: 2;
              }
            `}
          >
            <Img
              style={{
                marginBottom: `16px`
              }}
              fluid={data.cbApp1.childImageSharp.fluid}
            />
            <Img
              style={{
                marginBottom: `16px`
              }}
              fluid={data.cbApp2.childImageSharp.fluid}
            />
            <Img
              style={{
                marginBottom: `16px`
              }}
              fluid={data.cbApp3.childImageSharp.fluid}
            />
            <Img
              style={{
                marginBottom: `16px`
              }}
              fluid={data.cbApp4.childImageSharp.fluid}
            />
            <Img fluid={data.cbApp5.childImageSharp.fluid} />
          </div>
          <div
            css={css`
              @media only screen and (max-width: 600px) {
                grid-row: 1;
              }
            `}
          >
            <h3
              css={css`
                font-family: "Lato", sans-serif;
                font-weight: bold;
                font-size: 32pt;
                @media only screen and (max-width: 600px) {
                  font-size: 24pt;
                }
              `}
            >
              Coolblue iOS &amp; Android App
            </h3>
            <p
              style={{
                fontFamily: `Lato, sans-serif`,
                fontWeight: `lighter`,
                fontSize: `14pt`
              }}
            >
              In 2016 kon je zoeken wat je wilde maar er was er geen Coolblue
              app te vinden in de AppStore en Google Play.
              <br />
              Tegenwoordig hebben de iOS en Android app bij elkaar 150.000
              unieke gebruikers per maand. Een behoorlijke vooruitgang dus. En
              met trots kan ik zeggen dat ik als UX designer hieraan heb
              bijgedragen.
              <br />
              <br />
              Om deze app goed op te kunnen zetten hebben we feedback gevraagd
              van onze gebruikers op social media maar ook tijdens een co-
              creatie workshop.
              <br />
              Op basis van de feedback van onze toekomstige gebruikers en de
              data van de website heb ik app principles opgesteld, happy flows
              uitgewerkt voor alle features en vervolgens heb ik de wireframes
              en designs uitgewerkt.
              <br />
              <br />
              We begonnen met een klein team met 2 developers en een product
              owner. Tegenwoordig werk ik dagelijks in een volledig scrumteam
              met iOS &amp; Android developers, een klantreiziger, een product
              owner, een scrum master, een marketeer en een data analist om de
              app elke dag weer een beetje beter te maken en uit te breiden.
              <br />
              <br />
              Nieuwsgierig geworden? Download de app op je telefoon en probeer
              ‘m uit.
              <br />
              <span
                css={css`
                  width: 150px;
                  display: inline-block;
                  margin-left: -2%;
                  @media only screen and (max-width: 600px) {
                    margin-left: -4%;
                  }
                `}
              >
                <a href="https://itunes.apple.com/nl/app/coolblue/id1174047097?mt=8">
                  <img
                    style={{
                      maxWidth: `100%`,
                      width: `88%`,
                      margin: `6%`,
                      height: `auto`
                    }}
                    alt="Ontdek het op Google Play"
                    src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2017-08-04&kind=iossoftware&bubble=ios_apps"
                  />
                </a>
              </span>
              <span
                css={css`
                  width: 150px;
                  display: inline-block;
                  margin-left: -2%;
                `}
              >
                <a href="https://play.google.com/store/apps/details?id=eu.coolblue.shop&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img
                    style={{
                      maxWidth: `100%`,
                      height: `auto`
                    }}
                    alt="Ontdek het op Google Play"
                    src="https://play.google.com/intl/en_us/badges/images/generic/nl_badge_web_generic.png"
                  />
                </a>
              </span>
            </p>
          </div>
        </div>
      </Layout>
    )}
  />
);

export default CoolblueApp;
